import { useTranslations } from 'next-intl';
import { FC } from 'react';
import cls from './notFoundPage.module.css';
import { LinkSVG } from '@/shared/assets/images';
import Background from '@/shared/assets/images/ErrorPages/errorsBackground.png';
import lateralLines from '@/shared/assets/images/ErrorPages/lateralLines.png';
import { JeroldSVG } from '@/shared/assets/svg/Main';
import { getRouteWelcome } from '@/shared/const/router';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { AppImage } from '@/shared/ui/AppImage';
import { Icon } from '@/shared/ui/Icon';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';
import { Page } from '@/widgets/Page';

export const NotFoundPage: FC = () => {
  const t = useTranslations();
  const { push } = useAppNavigation();

  const redirectToMain = () => push({ path: getRouteWelcome() });

  return (
    <VStack max className={cls.wrapper} align='center'>
      <Icon
        clickable
        Svg={JeroldSVG}
        width={87}
        height={18}
        className={cls.logo}
        color='#fff'
        onClick={redirectToMain}
      />
      <Page justify='left'>
        <AppImage src={Background} alt='' className={cls.background} />
        <VStack
          align='center'
          justify='center'
          className={cls['not-found-page']}
        >
          <AppImage src={lateralLines} alt='' />
          <Text variant='h2' as='h2'>
            {'404'}
          </Text>
          <Text variant='h3' as='h3'>
            {t('Something went wrong')}
          </Text>
          <Text variant='body-l' align='center'>
            {t('text 404')}
          </Text>
          <Icon Svg={LinkSVG} width={24} height={24} color='#D2D9D8' />
          <Text variant='body-m' align='center'>
            {t('Please check the URL carefully and try again')}
          </Text>
        </VStack>
      </Page>
    </VStack>
  );
};
